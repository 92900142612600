import React from 'react';
import { Layout } from '@we-agile-you/retrospective-app';
import { RetrospectiveTemplate } from '@we-agile-you/retrospective-app';

// @ts-ignore
export default function RetroPage({ params }) {
  const retroId = params.retroId;

  return (
    <Layout>
      <RetrospectiveTemplate retroId={retroId} />
    </Layout>
  );
}
